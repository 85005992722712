<template>
  <section class="descr-list">
    <div class="container">
      <div class="descr-list__inner">
        <p class="descr-list__text">
          Протокол испытаний является официальным документом, который включает в себя результаты
          тестирования в аккредитованной испытательной лаборатории для следующих транспортных категорий:<br>
        </p>
        <ul class="descr-list__list">
          <li class="descr-list__item">
            легковые авто категории М1, их кузова и шасси, новые и б/у
          </li>
          <li class="descr-list__item">
            автобусы категории М2 и М3 (до 22 мест), их кузова и шасси
          </li>
          <li class="descr-list__item">
            грузовые автотранспортные средства категории N1, N2, N3, их кузова и шасси, ранее бывшие в
            употреблении
          </li>
          <li class="descr-list__item">
            легковые прицепы категории О1 и О2-О4 — новые и бывшие в употреблении
          </li>
        </ul>
        <p class="descr-list__text">
          Испытания осуществляются экспертами «ТЭЦ» в лабораторных условиях, оборудованных в соответствии
          с требованиями уполномоченного органа по сертификации.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'LightCarList'
}
</script>
